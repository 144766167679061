.c-content {
  margin-bottom: $spacing;
  max-width: 700px;

  h2, 
  h3,
  h4 {
    margin-top: 25px;

    @media (min-width: $breakpoint-10) {
      margin-top: 50px;
    }
  }


  figure {
    margin: 0 0 $spacing 0;
  }

  h3, 
  h4,
  h5 {
    font-size: 28px;
  }

  h6 {
    font-size: 16px;
  }

  p {
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: .002em;

    @media (--breakpoint-10) {
      font-size: 18px;
    }
  }
  
  ul + p { margin-top: 29px; }
  ol + p { margin-top: 29px; }

  figure + h2,
  figure + h3,
  figure + h4 {
    margin-top: 0;
  }
}