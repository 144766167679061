$block: 'c-pricing';
.#{$block} {
  
  list-style: none;
  width: 100%;
  margin-bottom: $spacing*2;

  @media (min-width: $breakpoint-20) {
    display: flex;
    margin-top: $spacing;
    margin-bottom: $spacing*3;
  }

  &__item {
    background: $color-white;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16);
    flex: 1 100%;
    margin-bottom: $spacing/2;

    @media (min-width: $breakpoint-20) {
      margin-bottom: 0;
      flex: calc(99.9%/3);
    }

    &:first-child {
      .#{$block}__header {
        background-color: $color-secondary-70;

        @media (min-width: $breakpoint-20) {
          border-radius: $border-radius-20 0 0 0;
        }
      }
    }

     &:last-child {
      .#{$block}__header {
        @media (min-width: $breakpoint-20) {
          border-radius: 0 $border-radius-20 0 0;
        }
      }
    }

    &--disabled {
      background: $color-neutral-10;

      .#{$block}__header {
        background-color: rgba($color-secondary-70, 0.5);
      }

      .#{$block}__content {
        color: $color-neutral-30;
      }
    }

    &:nth-child(2) {
      
      @media (min-width: $breakpoint-20) {
        margin-top: -$spacing;
        margin-bottom: -$spacing;
      }

      .#{$block}__content {
        @media (min-width: $breakpoint-20) {
          padding-bottom: $spacing*2;
        }
      }

      .#{$block}__attention {
        @media (min-width: $breakpoint-20) {
          margin-bottom: $spacing*3;
        }
      }
    }
  }

  &__header {
    color: $color-white;
    padding: $spacing/4*3 $spacing;
    font-size: $font-size-70;
    text-align: center;
    border-radius: $border-radius-20 $border-radius-20 0 0;
    background: $color-secondary-50 url(../images/pattern.svg);
    background-size: 300%;

    @media (min-width: $breakpoint-20) {
      font-size: $font-size-90;
    }
  }

  &__content {
    padding: $spacing $spacing $spacing*2 $spacing;
    text-align: center;
    font-family: $font-family-serif;

    @media (min-width: $breakpoint-20) {
      padding: $spacing*2 $spacing;
    }
  }

  &__spec {
    color: $color-secondary-70;
    margin-bottom: $spacing/4;

    .#{$block}__item--disabled & {
      color: $color-neutral-30;
    }


    &:last-child {
      font-family: $font-family-heading;
      color: $color-neutral-50;

      .#{$block}__item--disabled & {
        color: $color-neutral-30;
      }
    }
  }

  &__attention {
    font-family: $font-family-heading;
    color: $color-secondary-60;
    text-align: center;
    font-size: $font-size-100;
    position: relative;
    display: inline-block;
    padding: 0 $spacing*2;
    margin: $spacing 0;

    @media (min-width: $breakpoint-20) {
      margin: $spacing 0 $spacing*2 0;
    }

    &:before {
      content: '€';
      position: absolute;
      font-size: $font-size-100;
      top: 0;
      left: 0;

      @media (min-width: $breakpoint-20) {
        top: $spacing - 3;
        left: -$spacing;
      }

      .#{$block}__item--disabled & {
        display: none;
      }
    }

    .#{$block}__item--disabled & {
      font-size: $font-size-90;
      margin: $spacing*2 0 $spacing*3 0;
    }

    @media (min-width: $breakpoint-10) {
      font-size: 72px;
    }
  }
}

