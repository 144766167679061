.c-message {
  align-items: center;
  justify-content: space-between;
  background: $color-primary-30;
  border: 2px solid $color-primary-50;
  border-radius: $border-radius-10;
  color: $color-primary-50;
  display: flex;
  font-family: $font-family-heading;
  font-size: $font-size-30;
  margin-bottom: $spacing;
  padding: 0.5rem 0.75rem;
  vertical-align: baseline;
  padding-right: $spacing + 64;
  padding-left: $spacing/2;
  position: relative;
  min-height: 64px + 15;

  @media (min-width: $breakpoint-20) {
    padding-right: $spacing/2*3;
    padding-left: $spacing/2*3;
  }
  
  &__avatar {
    $size: 64px;

    border-radius: 100%;
    border: 2px solid $color-primary-50;
    box-shadow: $shadow-20;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: inline-block;
    height: $size;
    width: $size;
    margin: 0 0 0 $spacing/2;
  }

  &--neutral {
    background: $color-neutral-30;
    color: $color-neutral-70;
  }

  &--alert {
    background: $color-primary-10;
    color: $color-primary-50;
  }

  &--no-radius {
    border-radius: 0;
  }
}



/*li*/.c-messages__item--success {

  background: $color-success-70;
  color: $color-white;
}

/*li*/.c-messages__item--alert {

  background: $color-alert-50;
  color: $color-white;
}
