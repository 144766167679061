/*------------------------------------*\
  #BASE-FORMS
\*------------------------------------*/

form {  }

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  padding: 0;
}

input[type=text],
input[type=number],
input[type=password],
input[type=search],
input[type=email],
input[type=tel],
input[type=url],
textarea,
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: $color-neutral-10;
  border-radius: $border-radius-20;
  border: 1px solid $color-neutral-30;
  box-shadow: inset 0 1px 5px 0 rgba($color-black, 0.15);
  color: inherit;
  display: block;
  font-family: $font-family-heading;
  font-size: $font-size-40;
  line-height: 1.5em;
  margin: 0;
  outline: none;
  padding: 0.55rem 0.75rem 0.55rem 0.75rem;
  vertical-align: baseline;
  width: 100%;

  &:focus {
    border: 1px solid $color-neutral-50;
    box-shadow: 0px 0px 3px rgba($color-black, 0.1);
    outline: 0;
  }
}

textarea {
  display: block;
  overflow: auto;
  resize: vertical;
  vertical-align: top;
}

select {
  height: 2.75rem;

  &[multiple] {
    height: auto;
  }
}
