/*------------------------------------*\
  #BASE-QUOTE
\*------------------------------------*/

blockquote {
  color: $color-neutral-30;
  font-style: italic;
  line-height: 1.5;
  margin: 0;
  font-size: $font-size-50;
  padding: 0;
  position: relative;
  quotes: none;
  font-weight: 600;
}

cite {
  color: $color-neutral-30;
  display: block;
  font-size: $font-size-30;
  font-style: normal;
  font-weight: bold;
  margin-top: 5px;
}
