/*------------------------------------*\
  #OBJECTS-ICONS
\*------------------------------------*/

.c-icon {
  height: 16px;
  width: 16px;
  position: relative;
  display: inline-block;
  top: 2px;

  &--small {
    height: 12px;
    width: 12px;
  }

  &--medium {
    height: 20px;
    width: 20px;
  }

  &--large {
    height: 32px;
    width: 32px;
  }

  &--huge {
    height: 72px;
    width: 72px;
    margin: $spacing/4*3 0 $spacing*2 0;
  }
}

/*svg*/.c-icon--inline {
  position: relative;
  top: 0.35rem;
  margin: 0 0.5rem;
}
