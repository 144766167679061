.c-button,
button.c-button,
button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: linear-gradient(180deg, $color-primary-50, $color-primary-70);
  border-radius: $border-radius-20;
  border: 0;
  box-shadow: $shadow-10;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  font-family: $font-family-heading;
  font-size: $font-size-50;
  font-weight: normal;
  line-height: 1.5em;
  padding: 0.55rem 1rem 0.55rem 1rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-shadow: $shadow-10;
  vertical-align: bottom;

  &:focus { outline: 0; }

  &--block {
    display: block;
    width: 100%;
  }

  &--small {
    font-size: $font-size-30;
    padding: 0.25rem 0.75rem 0.25rem 0.75rem;
  }

  &--line {
    background: none;
    color: $color-primary-50;
    border: 2px solid $color-primary-50;
    text-shadow: none;
  }

  &--line-neutral {
    background: none;
    color: $color-primary-50;
    border: 2px solid $color-neutral-20;
    text-shadow: none;
    box-shadow: none;
  }

  &:hover {
    background: $color-primary-50;
    color: $color-white;
  }

  &:active,
  &:focus {
    background: $color-primary-70;
    box-shadow: inset 0 1px 3px rgba($color-black, 0.2);
  }

  .c-icon {
    fill: $color-white;
    position: absolute;
    right: $spacing/2;
    top: calc(50% - 12px);
  }
}

.c-button--neutral,
button.c-button--neutral, {
  border: 1px solid $color-neutral-30;
  color: $color-neutral-70;
  background: $color-white;
  font-size: $font-size-40;
  font-weight: normal;
  padding: 0.5rem 1.5rem 0.65rem 1.5rem;
  box-shadow: 0 0 0;
  text-shadow: 0 0 0;

  &:hover {
    background: $color-white;
    color: $color-neutral-90;
  }

  &:active,
  &:focus {
    background: $color-neutral-10;
    box-shadow: inset 0 1px 3px rgba($color-black, 0.2);
  }
}

.c-button--contrast {
  background: $color-black;
  color: $color-white;

  &:hover {
    background: $color-neutral-50;
    color: $color-neutral-70;
  }

  &:active,
  &:focus {
    box-shadow: inset 0 1px 3px rgba($color-black, 0.2);
  }
}

.o-button--success {
  background-color: $color-success-50;
  color: $color-white;

  &:hover {
    background-color: $color-success-70;
    color: $color-white;
  }

  &:active,
  &:focus {
    box-shadow: inset 0 1px 3px rgba($color-black, 0.2);
  }
}

.c-button--light {
  background: transparent;
  border: 1px solid $color-neutral-30;
  color: $color-white;

  &:hover {
    color: $color-white;
    background: rgba($color-white, 0.1);
  }

  &:active,
  &:focus {
    box-shadow: inset 0 1px 3px rgba($color-black, 0.2);
  }
}

.c-button-wrapper {
  .c-button {
    margin-bottom: $spacing/2;
  }
}
