$columns: 12;

// Generate Default CSS Rules
@include layout($grid-spacing-10);

@media (min-width: $breakpoint-10) {
  @include layout($grid-spacing-20);
}

@media (min-width: $breakpoint-30) {
  @include layout($grid-spacing-30);
}

.l-main {
  flex: 1 0 auto;
  min-height: 100%;
  min-height: 80vh;

  @-moz-document url-prefix() {
     min-height: auto;
  }
}

.l-single {
  margin: auto;
  max-width: 700px;

  &--wide {
    max-width: 900px;
  }

  &--small {
    max-width: 550px;
  }
}

@for $i from 2 through 12 {
  .l-#{$i} {
    flex: 1 100%;

    @media (min-width: $breakpoint-20) {
      flex-basis: calc(99.9% / #{$columns} * #{$i});
    }

    &--not-repons {
      flex-basis: calc(99.9% / #{$columns} * #{$i});
    }
  }
}

.l-flex {
  display: flex;
}

.l-row {
  flex-flow: row wrap;
}

.l-col {
  flex-flow: column wrap;
}

.l-align-center {
  align-items: center;
}

.l-flex-end {
  justify-content: flex-end;
}
