$block: 'c-feedback';
.#{$block} {
  $size: 64px; 

  position: relative;
  padding: 0 $size+20 0 0; 

  @media (min-width: $breakpoint-10) {
    float: right;
  }

  &__bubble {
    border-radius: $border-radius-10;
    background: $color-white;
    position: relative;
    padding: $spacing/2;
    line-height: 1;
    font-size: $font-size-30;
    box-shadow: $shadow-20;

    &:after {
      border-radius: $border-radius-10;
      position: absolute;
      top: calc(50% - 10px);
      right: -5px;
      content: '';
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid $color-white;
    }

    &:before {
      border-radius: $border-radius-20;
      background: rgba($color-black, 0.1);
      filter: blur(2px);
      content: '';
      position: absolute;
      right: -6px;
      width: 6px;
      height: 6px;
      top: calc(50% - 3px);
    }
  }

  &__avatar {
    border-radius: 100%;
    box-shadow: $shadow-20;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: inline-block;
    height: $size;
    width: $size;
    margin: 0;
    position: absolute;
    right: 0;
    top: calc(50% - #{$size/2}); 
  }
}