$block: 'c-progress';
.#{$block} {
  margin: $spacing/2 0;
  display: inline-block;
  width: 100%;

  &__bar {
    background: #3B2EB2;
    background: $color-neutral-10;
    border: 1px solid $color-neutral-20;
    border-radius: $border-radius-20;
    box-shadow: inset 0 1px 3px 0 rgba($color-black, 0.2);
    height: 12px;
    position: relative;
    margin-bottom: $spacing/4;
  }

  &__indicator {
    border-radius: $border-radius-20 $border-radius-20;
    background: $color-secondary-50;
    bottom: -1px;
    left: -1px;
    position: absolute;
    top: -1px;
    transform: translate3d(0,0,0);
    transition: $timing-10 $bezier-a;

    .#{$block}--alert & {
      background: $color-primary-50;
    }
  }

  &__content {
    color: $color-neutral-40;
    letter-spacing: 1px;
    font-size: $font-size-20;
    display: flex;
    font-family: $font-family-time;
    text-transform: uppercase;
    justify-content: space-between;
  }

  &__time-left {
    color: $color-secondary-50;

    .#{$block}--alert & {
      color: $color-primary-50;
    }

    span {
      color: $color-neutral-40;
    }
  }
}
