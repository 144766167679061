.c-paragraph {
  font-family: $font-family-serif;
  position: relative;
  font-size: $font-size-40;
  line-height: 1.75;

  &--spacing {
    margin-bottom: $spacing;
  }

  &--large-spacing {
    margin-bottom: $spacing*2;
  }

  &--no-spacing {
    margin-bottom: 0;
  }

  &--spacing-right {
    margin-right: $spacing*2;

    @media (min-width: $breakpoint-10) {
      margin-right: $spacing*3;
    }
  }

  &--light {
    color: $color-white;
  }

  &--soft {
    color: $color-neutral-40;
  }

  &--no-serif {
    font-size: $font-size-50;
    font-family: $font-family-heading;
  }

  &--large {
    line-height: 1.6;
    font-size: $font-size-70;
  }
}
