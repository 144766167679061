$block: 'c-sidebar';
.#{$block} {
  $size: 64px;
  $toggle-size: 44px;

  -webkit-overflow-scrolling: touch;
  background: $color-secondary-70;
  bottom: 0;
  color: $color-white;
  font-family: $font-family-heading;
  left: 0;
  // overflow-x: hidden;
  //overflow: auto;
  position: fixed;
  top: 0;
  width: calc(100% - #{$toggle-size});
  transform: translate3d(-100%, 0, 0);
  transform-style: preserve-3d;
  transition: $timing-10 $bezier-a;
  z-index: $z-index-30;

  @media (min-width: $breakpoint-30) {
    width: $sidebar;
    display: inline-block;
    flex-direction: column;
    transform: translate3d(0, 0, 0);
  }

  &__user {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    min-height: $size;
    padding: 0 0 0 $size+$spacing;
    position: relative;

    &:hover {
      .#{$block}__user__menu__list {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        visibility: visible;
      }
    }

    &__company {
      color: $color-neutral-40;
      font-family: $font-family-time;
      text-transform: uppercase;
      display: block;
    }

    &__name {
      margin: 0;
    }

    &__logo {
      background-color: $color-white;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 80%;
      border-radius: $border-radius-20;
      display: inline-block;
      height: $size;
      margin: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: $size;
    }
  }

  &__client-header {
    @include grid-container;
    padding: $spacing/4*3 $spacing;
    text-align: right;

    small {
      font-family: $font-family-time;
      color: $color-secondary-10;
      font-weight: normal;
      float: left;
      position: relative;
      top: 5px;
      text-transform: uppercase;
    }
  }

  &__current-client {
    background: $color-secondary-50;
    padding: $spacing/4*3 $spacing;
    margin-bottom: $spacing;
  }

  &__menu {
    display: block;
    padding: $spacing 0;
    list-style: none;
    
    &__link {
      color: $color-neutral-10;
      font-size: $font-size-40;
      display: block;
      line-height: 1;
      padding: $spacing/2 $spacing;

      &:hover {
        color: $color-white;
      }
    }
  }

  &__upper {

  }

  &__below {
    background: $color-secondary-90;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    @media (min-width: $breakpoint-10) {
      min-height: 180px;
    }
  }

  &__toggle {
    background: $color-secondary-90;
    border-radius: 0 $border-radius-20 $border-radius-20 0;
    box-shadow: $shadow-20;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $toggle-size;
    width: $toggle-size + 10;
    left: 0;
    text-align: center;
    position: fixed;
    top: calc(50% - #{$toggle-size/2});
    color: $color-white;
    z-index: $z-index-40;

    &:hover {
      color: $color-white;
    }

    @media (min-width: $breakpoint-30) {
      display: none;
    }

    .c-icon {
      fill: $color-white;
      top: 0;
      display: none;
    }
  }

  &__user__menu {
    position: relative;
    z-index: $z-index-30;
    box-shadow: $shadow-20;


    &__toggle {
      color: $color-neutral-30;
      font-size: $font-size-30;
    }

    &__list {
      background: $color-white;
      border-radius: $border-radius-20;
      color: $color-neutral-70;
      padding: $spacing/4 0;
      list-style: none;
      opacity: 0;
      margin: 0;
      transition: $timing-10 $bezier-a;
      position: absolute;
      top: $spacing + 5;
      transform: translate3d(0, -10px, 0);
      width: 140px;
      visibility: hidden;

      &:after {
        position: absolute;
        left: calc(50% - 3px);
        top: -5px;
        content: '';
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid $color-white;
      }
    }

    &__item {
      display: block;
    }

    &__link {
      font-size: $font-size-30;
      display: block;
      padding: $spacing/4 $spacing/2;
    }
  }
}

@keyframes sidebar {
    0% {
      opacity: 0;
      transform: skew(5deg) translate3d(0, -10px, 0) perspective(600) rotateX(-20deg);
    }
    100% {

      opacity: 1;
      transform: translate3d(0,0,0) perspective(0) rotateX(0deg) skew(0);
    }
}

body.c-sidebar--open {
  position: fixed;

  .#{$block} {
    transform: translate3d(0, 0, 0);
  }

  .#{$block}__toggle {
    right: 0;
    left: auto;
    border-radius: $border-radius-20 0 0 $border-radius-20;

    .c-icon {
      display: block;
    }

    span {
      display: none;
    }
  }
}