.c-panel {
  @include grid-container;
  background: $color-white;
  border-radius: $border-radius-20;
  box-shadow: $shadow-20;
  margin-bottom: $spacing*2;
  position: relative;

  &--max-width {
    
    @media (min-width: $breakpoint-40) {
      float: right;
      min-width: 320px;
      max-width: 320px;
    }
  }

  &__header {
    border-radius: $border-radius-20 $border-radius-20 0 0;
    background: $color-white;
    padding: $spacing $spacing $spacing/4 $spacing;
    border-bottom: 1px solid $color-neutral-10;

    @media (min-width: $breakpoint-20) {
      padding: $spacing $spacing/2*3 $spacing/4 $spacing/2*3;
    }
  }

  &__body {
    @include grid-container;    
    border-radius: $border-radius-20;
    border-radius: 0 0 $border-radius-20 $border-radius-20;
    display: inline-block;
    margin-bottom: $spacing;
    max-width: 550px;
    text-align: left;
    vertical-align: middle;
    width: 100%;
  }

  &__content {
    padding: $spacing $spacing 0 $spacing;

    @media (min-width: $breakpoint-20) {
      padding: $spacing $spacing/2*3 $spacing/4 $spacing/2*3;
    }
  }

  &__button {
    display: block;
  }
}