$block: 'c-form';
.#{$block} {
  @include grid-container;
  list-style: none;
  margin: 0 0 $spacing 0;
  position: relative;

  &--no-spacing {
    margin-bottom: 0;    
  }

  &__item {
    @include grid-container;

    &--clear {
      
    }

    &--nolabel {
      @media (min-width: $breakpoint-10) {
        padding-top: 26px;
      }
    }

    &--button {
      align-items: flex-end;
      clear: both;
      display: flex;
      padding-top: $spacing;

      .c-button {
        display: block;
        width: 100%;
      }
    }

    &--password {
      padding-top: 1rem;
      display: block;
      font-size: $font-size-30;
      text-align: center;
    }

    &--error {

      label {
        color: $color-primary-50;

        &:hover { color: $color-primary-70; }
      }

      input,
      input:focus {
        border: 1px solid $color-primary-50;
        background: $color-primary-10;
      }

      .c-form__errors {
        color: $color-primary-50;
        list-style: none;
        margin: 0.25rem 0 $spacing/2 0;
        font-size: $font-size-30;
      }
    }

    &--completed {

      label {
        color: $color-success-70;

        &:hover { color: $color-success-70; }
      }

      div.c-form__fields {
        position: relative;

        &:after {
          $size: 24px;

          background: url(../images/check-success.svg) no-repeat right center;
          background-size: 100%;
          content: '';
          display: inline-block;
          position: absolute;
          top: calc(50% - #{$size/2});
          right: $size/2;
          width: $size;
          height: $size;
          transition: $timing-10 $bezier-a;
        }
      }

      input,
      input:focus {
        border: 1px solid $color-success-70;
        background: rgba($color-success-70, 0.05);
      }
    }
  }

  &__label {
    clear: left;
    color: $color-secondary-70;
    font-family: $font-family-heading;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    font-size: $font-size-30;
    padding: 0.5rem 0 4px 0;
    text-align: left;
    width: 100%;

    small {
      margin-left: 2px;
    }

    &:hover {
      color: $color-neutral-90;
    }

    &:first-child {
      padding-top: 0;
    }

    &--large {
      font-size: $font-size-50;
      margin-bottom: 5px;
    }
  }

  &__fields {
    position: relative;
    margin-bottom: $spacing/4*3;

    &--no-spacing {
      margin-bottom: 0;
    }

    p, small {
      display: block;
      margin: 0.25rem 0;
    }
  }

  &__select {
    position: relative;
    border: 1px solid $color-neutral-30;
    border-radius: $border-radius-20;
    position: relative;

    &:after {
      $dimension: 10px;

      background: url(../images/arrow-down.svg) no-repeat;
      background-size: 100%;
      content: '';
      display: inline-block;
      pointer-events: none;
      height: $dimension;
      position: absolute;
      right: 1rem;
      top: calc( 50% - 3px );
      width: $dimension;
    }

    select {
      border: 0;
    }
  }

  .c-heading {
    position: relative;
  }

  &__radio-group {
    background: $color-neutral-20;
    box-shadow: inset 0 1px 5px 0 rgba($color-black, 0.15);
    border-radius: $border-radius-20;
    border: 1px solid $color-neutral-30;
    display: flex;
    margin: 0 0 $spacing 0;
    list-style: none;
    width: 100%;

    &__item {
      flex: 1;
      position: relative;
      font-size: $font-size-40;
      margin: 0;
      text-align: center;

      &:first-child {
        border-left: 0;
        border-radius: $border-radius-20 0 0 $border-radius-20;

        label.c-form__radio-group__label {
          border-radius: $border-radius-20 0 0 $border-radius-20;
        }
      }

      &:last-child {
        border-radius: 0 $border-radius-20 $border-radius-20 0;

        label.c-form__radio-group__label {
          border-radius: 0 $border-radius-20 $border-radius-20 0;
        }
      }

      label.c-form__radio-group__label {
        border-left: 1px solid $color-neutral-30;
      }
    }

    label {
      cursor: pointer;
      width: 100%;
    }

    .#{$block}__input {
      position: absolute;
      height: 1px;
      width: 1px;
      margin: -1px;
      padding: 0;
      border: 0;
      clip: rect(0 0 0 0);
    }

    input:checked {
      ~ label.c-form__radio-group__label {
        background: $color-secondary-50;
        color: $color-white;
      }
    }

   label.c-form__radio-group__label {
      padding: 5px 15px;
      display: block;
    }
  }

  label.radio-group__label {
    color: $color-neutral-70;
    display: block;
    font-weight: bold;
    padding: $spacing/4*3;
    position: relative;
    text-overflow: ellipsis;
    transition: color $timing-10 ease;
    white-space: nowrap;
    width: 100%;

    &:hover {
      color: $color-success-70;

      &:before {
        border-width: 3px;
      }
    }
  }
}

.c-form__checkbox {
  font-family: $font-family-heading;
  color: $color-secondary-70;
  position: relative;
  width: 100%;
  cursor: pointer;
  margin-bottom: 0em;
  display: inline-block;
  padding: 0 0 0 25px;
  font-weight: normal;
  font-size: $font-size-30;

  a {
    color: $color-neutral-70;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

    &:after {
      display: none;
    }
  }

  &:hover {
    text-decoration: none;
    color: $color-neutral-90;
  }

  &__box {
    $size: 16px;

    width: $size;
    height: $size;
    background: $color-neutral-10;
    display: block;
    position: absolute;
    left: 0;
    border: 1px solid $color-neutral-30;
    top: calc(50% - #{$size/2});
    border-radius: $border-radius-10;
    overflow: hidden;
    transition: border $timing-10 ease;

    &:before {
      background: url(../images/check-success.svg) no-repeat center;
      background-size: 100%;
      content: '';
      display: inline-block;
      position: absolute;
      top: 2px;
      left: 2px;
      right: 2px;
      opacity: 0;
      bottom: 2px;
      transition: $timing-10 $bezier-a;
    }
  }

  &__input {
    position: absolute;
    top: 0;
    left: -9999px;
  }

  &__input:checked ~ span.c-form__checkbox__box {

    &:before {
      animation: checkbox-animation 0.1s ease-out forwards;
      opacity: 1\9;
      background: $color-primary-50;
    }
  }
}

@keyframes checkbox-animation {
  0%   {
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/*p*/.c-form__description {
  margin: 0.5rem 0;
}


.c-form__checklist {
  list-style: none;
  margin: 0;

  li { margin: 0 0 2px 0; }

  label {
    cursor: pointer;

    &:hover { color: $color-neutral-90; }
  }
}

input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor:pointer;
  outline: none;
  display: inline-block;
  background: $color-white;
  border: 1px solid $color-neutral-30;
  width: 16px;
  height: 16px;
  padding: 1px;
  top: 2px;
  vertical-align: top;
  left: 0;
  margin: 0 0.25rem 0 0;
  border-radius: 100%;
  position: absolute;

  &:hover {
    border-color: $color-neutral-50;
  }

  &::-ms-expand {
      display: none;
  }
}

input[type="radio"]:checked:before {
    background: $color-primary-50;
    position: absolute;
    top: 2px;
    left: 2px;
    content: '';
    right: 2px;
    bottom: 2px;
    z-index: 999;
    border-radius: 100%;
}
