$block: 'c-site-nav';
.#{$block} {
  box-shadow: 0 2px 16px rgba($color-black, 0.3);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: $color-white;
  z-index: 999;
  width: 100%;

  @media (min-width: $breakpoint-20) {
    position: relative;
    background: none;
    box-shadow: none;
  }

  &__list {
    margin: 0;
    max-height: 100%;
    display: flex;
    width: 100%;
    justify-content: space-around;

    @media (min-width: $breakpoint-20) {
      display: inline-block;
      margin: 0 0 $spacing 0;
    }
  }

  &__item {
    display: inline-block;
  }

  &__link {
    color: $color-secondary-70;
    font-size: $font-size-40;
    line-height: 1;
    display: block;
    padding: $spacing/2;
    transition: $timing-20 $bezier-a;

    @media (min-width: $breakpoint-20) {
      color: $color-neutral-50;
      font-size: $font-size-50;
    }

    @media (min-width: $breakpoint-30) {
      padding: $spacing;
    }

    .#{$block}__item:first-child & {
      @media (min-width: $breakpoint-20) {
        padding-left: 0;
        margin-left: 0;
      }
    }

    &:hover {
      color: $color-neutral-70;
      background: rgba($color-white, 0.1);
    }

    .#{$block}__item--selected & {
      text-decoration: underline;
    }
  }

  &__logo {
    fill: $color-secondary-50;
    display: inline-block;
    position: absolute;
    top: 20px;
    left: 20px;

    @media (min-width: $breakpoint-10) {
      left: 25px;
      top: 25px;
    }

    @media (min-width: $breakpoint-20) {
      display: none;
    }

    svg {
      width: 80px;
      height: 24px;
    }
  }
}