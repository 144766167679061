/*------------------------------------*\
  #BASE-TABLES
\*------------------------------------*/

table {
  border-collapse: separate;
  border-radius: $border-radius-10;
  border-spacing: 0;
  margin-bottom: $spacing;
  width: 100%;
}

thead th {
  padding: $spacing / 2;
  text-align: left;
  background: $color-primary-50;
  color: $color-white;
  font-weight: 600;
}

tbody {

  // tr:nth-child(2n) { background: $color-neutral-10; }

  td {
    padding: $spacing / 2;
  }
}
