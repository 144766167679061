$block: 'c-card';
.#{$block} {
  @include grid-container;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16);
  border-radius: $border-radius-10;
  background: $color-white;
  margin-bottom: $spacing/2;
  position: relative;
  width: 100%;

  &--has-link,
  &--dotted {
    box-shadow: none;
    background: none;
    border-radius: 0;
  }

  &--dotted {
    display: flex;
    font-size: $font-size-30;
    color: $color-secondary-70;
    align-items: center;
    font-weight: bold;
    justify-content: center;
    flex-direction: column;
    border: 2px dotted $color-neutral-20;
  }

  &__plus {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-bottom: $spacing/2;
    text-align: center;
    background: $color-neutral-20;
    height: 64px;
    width: 64px;

    svg {
      fill: $color-secondary-70;
    }
  }

  small {
    display: block;
    margin-bottom: $spacing/2;
  }

  @media (min-width: $breakpoint-10) {
    margin-bottom: $spacing;
  }

  &__link {
    border-radius: $border-radius-20;
    display: block;
    position: relative;
    transform: translate3d(0, 0, 0);
    transition: $timing-10 $bezier-a;

    .#{$block}--has-link & {
      background: $color-white;
    }

    &:before {
      border-radius: $border-radius-20;
      box-shadow: $shadow-20;
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.5;
      transition: $timing-10 $bezier-a;
    }

    &:hover,
    &:focus {
      transform: translate3d(0, -1px, 0);

      &:before {
        opacity: 1;
      }
    }

    &--no-shadow {
      align-items: center;
      color: $color-secondary-70;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      width: 100%;

      &:hover {
        background: $color-white;
        color: $color-secondary-70;
      }

      &:before {
        display: none;
      }
    }
    &--selected {

      .#{$block}__button {
        color: $color-primary-50;
        border-color: $color-primary-50;
        position: relative;

        &:after {
          $size: 24px;

          background: url(../images/check-success.svg) no-repeat;
          background-size: 100%;
          content: '';
          display: inline-block;
          position: absolute;
          top: calc(50% - #{$size/2});
          left: $size/2;
          width: $size;
          height: $size;
          transition: $timing-10 $bezier-a;
        }
      }
    }
  }

  &__image {
    margin: 0;

    img {
      padding: 0 $spacing*2;
      display: block;
      width: 100%;
      max-width: 100%;

      @media (min-width: $breakpoint-10) {
        padding: 0 $spacing;
      }
    }

    &--width-auto {
      border: 0;
      padding-bottom: 0;
      height: auto;

      img {
        width: auto;
        margin: auto;
        min-height: 140px;
        max-height: 140px;
      }
    }
  }

  &__title {
    color: $color-neutral-70;
    margin-bottom: 2px;
  }

  &__price-wrapper {
    @include grid-container;
  }

  &__price {
    color: $color-neutral-90;
    margin-bottom: $spacing/2;
    text-align: right;
    font-weight: 600;
    float: right;
    font-size: $font-size-50;

    &__cents {
      font-size: $font-size-20;
      position: relative;
      top: -3px;
      margin-left: 1px;
    }

    &--large {
      margin-bottom: $spacing/3;
      font-size: $font-size-100;

      .#{$block}__price__cents {
        font-size: $font-size-40;
        top: -13px;
      }
    }
  }

  &__content {
    @include grid-container;
    padding: $spacing $spacing;
  }

  &__footer {
    @include grid-container;
    border-radius: 0 0 $border-radius-10 $border-radius-10;
    background: $color-neutral-10;
    color: $color-neutral-70;
    border-top: 1px solid $color-neutral-30;
    padding: $spacing/2 $spacing 0 $spacing;

    &--light {
      background: $color-white;
    }
  }

  &__button {
    display: block;

    &__icon {
      display: none;
    }
  }

  &__arrow-button {
    display: flex;
    justify-content: space-between;
    font-size: $font-size-20;
    border-radius: 0 0 $border-radius-20 $border-radius-20;
    background: $color-neutral-10;
    color: $color-neutral-70;
    padding: $spacing/4*3 $spacing;

    &:hover {
      color: $color-black;
    }
  }
}

.c-card__image--background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex: auto;
  height: 0;
  margin: 0;
  padding-bottom: 56.25%;
}

.c-cards-wrapper {
  margin: 0 0 0;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: $breakpoint-20) {
    margin: 0 0 $spacing -20px;
  }

  .c-card {
    flex: 0 0 100%;
    margin: $spacing 0 $spacing 0;
    flex-grow: 0;
    flex-shrink: 0;

    &:first-child {
      margin-top: 0;
    }

    @media (min-width: $breakpoint-20) {
      margin: 0 0 $spacing $spacing;
      flex: 0 0 calc(99.9%/3 - #{$spacing});
      flex-basis: 0 0 calc(99.9%/3 - #{$spacing});

       //IE10+ flex fix: */
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        flex: 0;
        min-width: calc(25% - #{$spacing});
      }
    }
  }
}
