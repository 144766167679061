$block: 'c-time-entry';
.#{$block}{
  border-radius: $border-radius-20;
  background: $color-white;
  
  box-shadow: $shadow-20;
  margin-bottom: $spacing;
  min-width: 300px;

  &__header {
    padding: $spacing $spacing $spacing/4 $spacing;
    border-bottom: 1px solid $color-neutral-10;  }

  &__content {
    padding: 0 $spacing/2 $spacing/2 $spacing/2;
  }

  &__form {
    margin-bottom: 0;
    align-content: flex-end;
    justify-content: flex-start;


    .c-form__item:last-child {
      padding-top: 0;
    }
  }

  &__timer {

  }

  // ::-webkit-input-placeholder {
  //   font-size: $font-size-80;
  //   font-weight: bold;
  //   position: relative;
  //   top: 3px;
  // }

  // ::-moz-placeholder {
  //   font-size: $font-size-80;
  //   font-weight: bold;
  //   position: relative;
  //   top: 3px;
  // }

  // :-ms-input-placeholder {
  //   font-size: $font-size-80;
  //   font-weight: bold;
  //   position: relative;
  //   top: 3px;
  // }
}